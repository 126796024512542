import { configureStore, combineReducers } from "@reduxjs/toolkit"
import StateSlice from "./slices/states"
import Page from "./slices/pages"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import SearchSlice from './slices/search'



const persistConfig = {
  key: "root",
  storage,
  
}

const rootReducer = combineReducers({
  state: StateSlice,
  page: persistReducer(persistConfig, Page),
  search: persistReducer(persistConfig, SearchSlice),
})

// const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
})

export default store
